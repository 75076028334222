<template>
  <div style='height:920px;float:left;width:100%' class="px-4 py-2 pt-4">
    <!-- <a-tabs default-active-key="2" @change="callback">
      <a-tab-pane key="1" tab="通知(8)">
        通知
      </a-tab-pane>
      <a-tab-pane key="2" tab="留言" force-render>
        <message></message>
      </a-tab-pane>
    </a-tabs> -->
    <message></message>
  </div>
</template>

<script>
import Message from "./components/message";
export default {
  data() {
    return {};
  },
  components: {
    Message,
  },
  methods: {
    callback(key) {
      console.log(key);
    },
  },
};
</script>

<style></style>
