<template>
  <div class="message">
    <div class="pb-4 border-gray-100 items" v-for="item in list" :key="item.id">
      <div class="msg_item">
        {{ item.content }}
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center pt-4">
          <div class="bg-red avatars_box">
            <img :src="item.avatar" alt="" />
          </div>
          <div style='color:#294DEB' class="pl-2 ">{{ item.nickname }}</div>
          <div class="pl-1 text-gray-300">的留言</div>
          <div class="pl-4 text-sm text-gray-300">{{ item.create_time }}</div>
        </div>
        <div class="flex items-center">
          <div style='padding:5px 10px;margin-right:10px;border-radius:6px;font-size:17px;color:#fff;background-color:#294deb;text-align:center;'
            class=" text-sm cursor-pointer text-primary"
            @click="replyMes(item)"
          >
            回复
          </div>
          <div
            style="padding:5px 10px;margin-right:10px;border-radius:6px;font-size:17px;color:#FF1818;background-color:#F8E1E1;text-align:center;"
            class="flex items-center justify-center text-xs text-white rounded "
            v-if="item.status == 0"
          >
            新!
          </div>
        </div>
      </div>
      <div class="p-2 mt-2 bg-gray-100 rounded" v-if="item.reply.length > 0">
        <div
          v-for="child in item.reply"
          :key="item.id"
          class="flex items-center py-1"
        >
          <div class="text-primary">
            {{ child.member_id == item.member_id ? item.nickname : "系统回复" }}
          </div>
          <div>: {{ child.content }}</div>
        </div>
      </div>
    </div>
    <div class="flex justify-end pt-4 pb-2">
      <a-pagination
        v-model="current"
        @change="change"
        :default-page-size="limit"
        :total="total"
        show-less-items
        show-quick-jumper
        :show-total="(total) => `共 ${total} 条留言`"
      />
    </div>
    <a-modal v-model="visible" title="回复留言" @ok="handleOk">
      <div>
        <a-textarea
          v-model="value"
          placeholder="请输入内容"
          :auto-size="{ minRows: 3, maxRows: 10 }"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { commentList, commentRead, reply } from "@/api/notify";
export default {
  data() {
    return {
      current: 1,
      limit: 7,
      list: [],
      total: 0,
      unReadList: [],
      visible: false,
      msgId: "",
      value: "",
    };
  },
  created() {
    this.aquireMessageList();
  },
  methods: {
    replyMes(item) {
      this.msgId = item.id;
      this.visible = true;
    },
    // 回复留言
    handleOk() {
      if (this.value == "" || this.value.length == 0) {
        this.$message.error("请输入回复内容");
        return;
      }
      reply({
        id: this.msgId,
        content: this.value,
      }).then((res) => {
        console.log("回复的结果", res);
        if (res.code == 200) {
          this.visible = false;
          this.value = "";
          this.$message.success("回复成功");
          this.aquireMessageList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    change(page) {
      this.current = page;
      this.aquireMessageList();
    },
    aquireMessageList() {
      commentList({
        page: this.current,
        limit: this.limit,
      })
        .then((res) => {
          console.log("获取留言", res);
          if (res.code == 200) {
            this.list = [];
            this.list.push(...res.data.list);
            this.total = res.data.count;
            this.unReadList = [];
            res.data.list.forEach((item) => {
              if (item.status == 0) {
                this.unReadList.push(item.id);
              }
            });
            if (this.unReadList.length > 0) {
              this.commentReadFunc();
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    commentReadFunc() {
      commentRead({ comment_id: JSON.stringify(this.unReadList) }).then(
        (res) => {
          console.log(res);
          if (res.code == 200) {
            this.unReadList = [];
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.items{background-color:#fff;border-radius:20px;padding:20px 10px 15px 20px;}
.msg_item {
  word-wrap: break-word;
  word-break: normal;
}
.avatars_box {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  background: #cccccc;
  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
}
.message .items:not(:last-child) {
  border-bottom: 2px solid #e9e9e9;
}
.message .items:not(:first-child) {
  margin-top: 12px;
}
</style>
